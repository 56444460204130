import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import SEO from '../components/seo';
import { PageTitle } from '../components/styles/global';

import DownloadIcon from '../images/download.inline.svg';
import statut from '../downloads/ppp-statut.pdf';
import deklaracjaCzlonka from '../downloads/ppp-deklaracja-czlonkowska.pdf';
import listaZalacznikow from '../downloads/ppp-lista-zalacznikow-dodatkowych-do-deklaracji-czlonkowskiej.pdf';
import zalacznikDoDeklaracji from '../downloads/ppp-zalaczniki-do-deklaracji.pdf';
import wymaganiaDlaKandydatow from '../downloads/ppp-wymagania-dla-kandydatow.pdf';
import regulaminSkreslaniaZListyCzlonkow from '../downloads/ppp-regulamin-skreslania-osob-z-listy-czlonkow.pdf';
import regulaminCzlonkaWspierajacego from '../downloads/ppp-regulamin-czlonka-wspierajacego.pdf';
import deklaracjaCzlonkaWspierajacego from '../downloads/ppp-deklaracja-czlonkowska-czlonek-wspierajacy.pdf';

const Address = () => (
  <address>
    Beata Kwaśniewska
    <br />
    Professional Piercers Poland
    <br />
    UL. Norwida 13/U1
    <br />
    80-280 Gdańsk
  </address>
);

const JoinUs = (props) => {
  const {
    site: { siteData },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteData: siteMetadata {
            email
            address {
              street
              postal
              city
            }
            account {
              number
              bankName
            }
          }
        }
      }
    `
  );
  const {
    t,
    i18n: { language },
  } = props;
  const { address, email, account } = siteData;
  const downloadStatue = (
    <a href="#attachments" className="link">
      pobierz
    </a>
  );

  const STEP_4 = {
    pl: (
      <>
        Dokonaj darowizny w wysokości 100zł na rachunek bankowy Stowarzyszenia:
        <address>
          Professional Piercers Poland
          <br />
          {address.street}, {address.postal} {address.city}
          <br />
          Nr konta ({account.bankName}): {account.number}
        </address>
        W tytule wpisz: <q>„Darowizna, [Twoje imię i nazwisko]".</q>
        Nie wpisuj w tytule żadnych dodatkowych informacji.
        <p className="warning">
          Darowizna na rzecz Stowarzyszenia stanowi formę wpisowego, niezbędnego do rozpatrzenia
          Twojej deklaracji i jest bezzwrotna niezależnie od decyzji podjętej przez Zarząd
          Stowarzyszenia. Potwierdzenie dokonania darowizny dołącz do formularza zgłoszeniowego jako
          załącznik.
        </p>
      </>
    ),
    en: (
      <>
        Make a donation of 100 zł to support the association. Bank details:
        <address>
          Professional Piercers Poland
          <br />
          {address.street}, {address.postal} {address.city}, Poland
          <br />
          Bank account number: ({account.bankName}): {account.number}
        </address>
        In the title of bank transfer provide details: <q>„Donation, [Name, Surname]".</q> Don't
        provide any additional information.
        <p className="warning">
          Donation for the Association is one's registration fee and is non-returnable regardless
          the decision made by the Association. Attach the confirmation to your enrollment forms as
          the attachment.
        </p>
      </>
    ),
  };

  const STEP_7 = {
    pl: (
      <>
        Formularz zgłoszeniowy wraz z kompletem dokumentów wyślij na adres mailowy{' '}
        <a href={`mailto:${email}`} className="link">
          {email}
        </a>
        , w tytule wiadomości wpisując:
        <q>„Deklaracja Członkowska, [Twoje imię i nazwisko]”</q>. Ponadto, oryginały deklaracji
        członkowskiej oraz poparcia członków stowarzyszenia wyślij na adres pocztowy (za
        potwierdzeniem odbioru):
        <address>
          Beata Kwaśniewska
          <br />
          Professional Piercers Poland
          <br />
          UL. Norwida 13/U1
          <br />
          80-280 Gdańsk
        </address>
      </>
    ),
    en: (
      <>
        Application form with completed documents should be sent to{' '}
        <a href={`mailto:${email}`} className="link">
          {email}
        </a>
        , write <q>„Membership Declaration, [Name, Surname]”</q> and postal address (return
        receipt):
        <address>
          Beata Kwaśniewska
          <br />
          Professional Piercers Poland
          <br />
          UL. Norwida 13/U1
          <br />
          80-280 Gdańsk
        </address>
      </>
    ),
  };

  return (
    <>
      <SEO title={t('joinUs')} />
      <PageTitle>{t('joinUs')}</PageTitle>
      <div>
        <h2>{t('joinUs8Steps')}</h2>
        <ol>
          <li>
            <Trans i18nKey="joinUsStep1" components={[downloadStatue]} />
          </li>
          <li>{t('joinUsStep2')}</li>
          <li>{t('joinUsStep3')}</li>
          <li>{t('joinUsStep4')}</li>
          <li>{STEP_4[language]}</li>
          <li>{t('joinUsStep6')}</li>
          <li>{STEP_7[language]}</li>
          <li>{t('joinUsStep8')}</li>
        </ol>
        <p>{t('joinUsAppeal')}</p>
        <p className="warning">{t('joinUsWarning')}</p>
        <span>
          {t('joinTheGroup')}:{' '}
          <OutboundLink href="https://www.facebook.com/groups/rekrutacjaPPP" className="link">
            facebook.com/groups/rekrutacjaPPP
          </OutboundLink>
        </span>
      </div>
      <h3 id="attachments">{t('joinUsAttachments')}</h3>
      <p>
        <a href={statut} className="file pdf" target="_blank" rel="noopener noreferrer">
          <DownloadIcon width="18" />
          {t('statute')} (.pdf)
        </a>
        <a href={deklaracjaCzlonka} className="file pdf" target="_blank" rel="noopener noreferrer">
          <DownloadIcon width="18" />
          {t('membershipDeclaration')} (.pdf)
        </a>
        <a href={listaZalacznikow} className="file pdf" target="_blank" rel="noopener noreferrer">
          <DownloadIcon width="18" />
          {t('listOfAttachments')} (.pdf)
        </a>
        <a
          href={zalacznikDoDeklaracji}
          className="file pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadIcon width="18" />
          {t('declarationAttachment')} (.pdf)
        </a>
        <a
          href={wymaganiaDlaKandydatow}
          className="file pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadIcon width="18" />
          {t('reqirementsForCandidates')} (.pdf)
        </a>
        <a
          href={regulaminSkreslaniaZListyCzlonkow}
          className="file pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadIcon width="18" />
          {t('removingRegulations')} (.pdf)
        </a>
      </p>
      <h2>{t('supportingMemberHeader')} </h2>
      <p>{t('supportingDescription')}</p>
      <p>
        {t('supportingOffer')} ({' '}
        <a href={statut} className="file pdf inline" target="_blank" rel="noopener noreferrer">
          <DownloadIcon width="18" />
          {t('offerAttachment')} (.pdf)
        </a>
        )
      </p>
      <p>
        {t('supportingDisclaimer')}
        <Address />
      </p>
      <h3 id="attachments">{t('joinUsAttachments')}</h3>
      <p>
        <a
          href={deklaracjaCzlonkaWspierajacego}
          className="file pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadIcon width="18" />
          Deklaracja członkowska - członek wspierający (.pdf)
        </a>
        <a
          href={regulaminCzlonkaWspierajacego}
          className="file pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadIcon width="18" />
          Regulamin członka wspierającego (.pdf)
        </a>
      </p>
    </>
  );
};
export default withTranslation()(JoinUs);
